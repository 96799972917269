.react-calendar-heatmap .color-empty { fill: #ebedf0; }
.react-calendar-heatmap .color-scale-1 { fill: #e3f2fd; }
.react-calendar-heatmap .color-scale-2 { fill: #bbdefb; }
.react-calendar-heatmap .color-scale-3 { fill: #90caf9; }
.react-calendar-heatmap .color-scale-4 { fill: #64b5f6; }
.react-calendar-heatmap .color-scale-5 { fill: #42a5f5; }
.react-calendar-heatmap .color-scale-6 { fill: #2196f3; }
.react-calendar-heatmap .color-scale-7 { fill: #1e88e5; }
.react-calendar-heatmap .color-scale-8 { fill: #1976d2; }
.react-calendar-heatmap .color-scale-9 { fill: #1565c0; }
.react-calendar-heatmap .color-scale-10 { fill: #0d47a1; }


